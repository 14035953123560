body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Gota;
  src: url('./Fonts/Gota-Regular.eot');
  src: url('./Fonts/Gota-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Gota-Regular.woff2') format('woff2'), 
    url('./Fonts/Gota-Regular.woff') format('woff'),
}

@font-face {
  font-family: Gota-light;
  src: url('./Fonts/Gota-Light.eot');
  src: url('./Fonts/Gota-Light.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Gota-Light.woff2') format('woff2'), 
    url('./Fonts/Gota-Light.woff') format('woff'),
}
