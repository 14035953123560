.project-display{
    overflow: hidden;
    position: relative;
    background-color: rgb(65, 65, 65);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 3px solid #98ffcc;
    border-radius: 10px;
    width: 60vw;
    height: 45vh;
}
.default-display{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 60vw;
}
.hover-display{
    transform: translateY(-100%);
    display: flex;
    align-items: center;
    background: fixed;
    position: absolute;
    width: 60vw;
    height: 45vh;
    transition: transform 0.5s ease;
    z-index: 3;
}
.slide-container{
    display: flex;
    height: 100%;
    width: 100%;
}
.project-display:hover .hover-display{
    transform: translateY(0);
}
.light-background{
    background-color: rgb(223, 223, 223);
}
.dark-background{
    background-color: rgb(65, 65, 65);
}
.display-image{
    max-height: 350px;
}

@media (max-width: 1000px) {
    .project-display{
        height: 80vh;
        width: 80vw;
    }
    .default-display{
        flex-direction: column;
        gap: 3rem;
    }
    .display-image{
        width: 75vw;
        max-width: fit-content;
    }
    .hover-display{
        height: 80vh;
        width: 80vw;
    }
}
