.slide{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    width: 60vw;
    height: 100%;
    padding: 0 4rem;
}
.light-background{
    background-color: rgb(204, 204, 204);
}
.dark-background{
    background-color: rgb(53, 53, 53);
}
.repo{
    font-size: 20px;
    text-decoration: underline;
}
.dark-background .repo{
    color: rgb(204, 204, 204);
}
.light-background .repo{
    color: rgb(53, 53, 53);
}
.description{
    width: calc(60vw - 8rem);
    font-size: 25px;
}
.slide-text{
    width: calc(60vw - 8rem);
    font-size: 15;
}
.slide-image{
    height: 250px;
}
.slide-button-container{
    margin-top: 1.5rem;
}
.arrow{
    background: none;
    border: none;
    cursor: pointer;
    height: 60px;
    width: 40px;
}
.right-dark-arrow{
    background: url(../../Images/dark-right-arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.right-light-arrow{
    background: url(../../Images/light-right-arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.left-dark-arrow{
    background: url(../../Images/dark-left-arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
}
.left-light-arrow{
    background: url(../../Images/light-left-arrow.png);
    background-size: contain;
    background-repeat: no-repeat;
}

@media (max-width: 1000px){
    .slide{
        padding: 0 1rem;
        width: 80vw;
    }
    .description{
        width: calc(80vw - 2rem);
    }
    .slide-text{
        width: calc(80vw - 2rem);
    }
    .slide-image{
        height: fit-content;
        width: 70vw;
    }
}