.page-header{
    background-color: #98ffcc;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 2;
}
.logo{
    display: flex;
    padding: 0.7rem;
    align-items: end;
    padding-left: 2.5rem;
    color: rgb(63, 63, 63);
}
.logo-letters{
    font-family: Gota;
    font-size: 35px;
}
.small-logo-letters{
    font-family: Gota;
    font-size: 25px;
    bottom: 10px;
    padding-bottom: 2px;
}
.dark-mode-button{
    background: url(/src/Images/pullcord.png);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 20px;
    height: 100px;
    border: none;
    display: flex;
    align-items: start;
    position: absolute;
    top: 3rem;
    left: 2rem;
    z-index: 1;
  }
.dark-mode-button:hover{
    top: 3.7rem;
}
.links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5rem 4px 0;
    width: 20vw;
}
.header-link{
    color: rgb(63, 63, 63);
    text-decoration: none;
    font-weight: 500;
    padding: 8px;
}

@media (max-width: 1000px){
    .page-header{
        justify-content: space-between;
    }
    .logo{
        padding-left: .7rem;
    }
    .links{
        width: fit-content;
        padding-right: 0;
    }
    .dark-mode-button{
        top: 1.5rem;
        left: 1rem;
    }
    .dark-mode-button:hover{
        top: 2rem;
    }
}