.App {
  text-align: center;
}
p{
  margin: 0;
}
h1{
  margin: 0;
}
h2{
  margin: 0;
}
h3{
  margin: 0;
}
