.dark-mode{
    color: rgb(200, 200, 200);
}
.light-mode{
    color: rgb(53, 53, 53);
}
.contact-head{
    padding-top: 0rem;
}
.input-row{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.input-label{
    padding: 1rem;
}
.input-box{
    width: 300px;
    padding: 8px;
}
.email-form{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.hide-form{
    display: none;
}
.email-input{
    width: 500px;
}
.email-message{
    margin-top: 2rem;
    padding: 8px;
    width: 34vw;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.company-input{
    width: 400px;
    padding: 8px;
}
.hide-submit-display{
    display: none;
}
.submit-button{
    margin-top: 1rem;
    font-size: 20px;
    padding: 8px;
    background-color: white;
    border-radius: 5px;
}

@media (max-width: px){
    .input-row{
        flex-direction: column;
    }
    .email-message{
        width: 300px;
    }
    .contact-page{
        padding: 2rem 0;
    }
}