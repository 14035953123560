.projects-page{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: fit-content;
    padding-bottom: 3rem;
}
.projects-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    padding-top: 3rem;
}