.page{
    min-height: calc(100vh - 4rem);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.light-mode{
    background-color: rgb(204, 204, 204);
}
.dark-mode{
    background-color: rgb(53, 53, 53);
}
.name{
    font-size: 90px;
}
.title{
    margin-top: 1rem;
    font-size: 40px;
}
.homepage-discription{
    margin-top: 2rem;
}

@media (max-width: 1000px) {
    .name{
        font-size: 35px;
    }
    .title{
        font-size: 30px;
    }
}